import {pullRequestUrl} from '@github-ui/issue-viewer/Urls'
import {useFeatureFlags} from '@github-ui/react-core/use-feature-flag'
import type {AnalyticsEvent} from '@github-ui/use-analytics'
import React, {type ForwardedRef, forwardRef, type ReactElement, Suspense, useCallback, useMemo} from 'react'
import {graphql, useSubscription} from 'react-relay'
import {useFragment, usePreloadedQuery, type PreloadedQuery} from 'react-relay/hooks'

import type {PullRequestItem$key} from './__generated__/PullRequestItem.graphql'
import {PullRequestItem} from './PullRequestItem'
import type {PullRequestRow_pullRequest$key} from './__generated__/PullRequestRow_pullRequest.graphql'
import type {PullRequestRowCommentsSubscription} from './__generated__/PullRequestRowCommentsSubscription.graphql'
import type {PullRequestRowCommitChecksSubscription} from './__generated__/PullRequestRowCommitChecksSubscription.graphql'
import type {PullRequestRowReviewSubscription} from './__generated__/PullRequestRowReviewSubscription.graphql'
import type {PullRequestRowStatusUpdatedSubscription} from './__generated__/PullRequestRowStatusUpdatedSubscription.graphql'
import type {PullRequestRowTitleUpdatedSubscription} from './__generated__/PullRequestRowTitleUpdatedSubscription.graphql'
import type {QUERY_FIELDS} from '../constants/queries'
import type {IssueRowSecondaryQuery} from './__generated__/IssueRowSecondaryQuery.graphql'
import {ScopedCommands} from '@github-ui/ui-commands'
import styles from './PullRequestRow.module.css'
import {usePullRequestRowSubscription} from './PullRequestRowSubscription'
import {isLoggedIn} from '@github-ui/client-env'
import {IssuesIndexSecondaryGraphqlQuery} from './IssueRow'
import type {PullRequestRowSecondary$key} from './__generated__/PullRequestRowSecondary.graphql'
import type {To} from 'react-router-dom'

export const PullRequestRowSecondaryFragment = graphql`
  fragment PullRequestRowSecondary on PullRequest {
    headCommit {
      commit {
        id
      }
    }
    # eslint-disable-next-line relay/must-colocate-fragment-spreads
    ...IssuePullRequestStateIconSecondary
    # eslint-disable-next-line relay/must-colocate-fragment-spreads
    ...ReviewDecision
    # eslint-disable-next-line relay/must-colocate-fragment-spreads
    ...CheckRunStatusFromPullRequest
  }
`

export function usePullRequestSubscriptions(pullRequestId: string) {
  const statusConfig = useMemo(
    () => ({
      subscription: graphql`
        subscription PullRequestRowStatusUpdatedSubscription($id: ID!) {
          pullRequestStatusUpdated(id: $id) {
            id
            state
            isDraft
          }
        }
      `,
      variables: {id: pullRequestId},
    }),
    [pullRequestId],
  )

  const titleConfig = useMemo(
    () => ({
      subscription: graphql`
        subscription PullRequestRowTitleUpdatedSubscription($id: ID!) {
          pullRequestTitleUpdated(id: $id) {
            id
            title
            titleHTML
          }
        }
      `,
      variables: {id: pullRequestId},
    }),
    [pullRequestId],
  )

  const commentsConfig = useMemo(
    () => ({
      subscription: graphql`
        subscription PullRequestRowCommentsSubscription($id: ID!) {
          pullRequestCommentsUpdated(id: $id) {
            id
            totalCommentsCount
          }
        }
      `,
      variables: {id: pullRequestId},
    }),
    [pullRequestId],
  )

  const reviewDecisionConfig = useMemo(
    () => ({
      subscription: graphql`
        subscription PullRequestRowReviewSubscription($id: ID!) {
          pullRequestReviewDecisionUpdated(id: $id) {
            id
            reviewDecision
          }
        }
      `,
      variables: {id: pullRequestId},
    }),
    [pullRequestId],
  )

  useSubscription<PullRequestRowStatusUpdatedSubscription>(statusConfig)
  useSubscription<PullRequestRowTitleUpdatedSubscription>(titleConfig)
  useSubscription<PullRequestRowCommentsSubscription>(commentsConfig)
  useSubscription<PullRequestRowReviewSubscription>(reviewDecisionConfig)
}

function LazyCommitChecksUpdateSubscriptionWrapperInternal({
  secondaryDataKey,
}: {
  secondaryDataKey?: PullRequestRowSecondary$key
}) {
  const data = useFragment(PullRequestRowSecondaryFragment, secondaryDataKey)

  if (!data || !data.headCommit) return null

  return <CommitChecksUpdateSubscriptionWrapper commitId={data.headCommit.commit.id} />
}

function LazyCommitChecksUpdateSubscriptionWrapperFetched({
  id,
  secondaryQueryRef,
}: {
  id: string
  secondaryQueryRef: PreloadedQuery<IssueRowSecondaryQuery>
}) {
  const {nodes} = usePreloadedQuery<IssueRowSecondaryQuery>(IssuesIndexSecondaryGraphqlQuery, secondaryQueryRef)
  const prNode = nodes?.find(node => node?.id === id)

  if (!prNode) return null

  return <LazyCommitChecksUpdateSubscriptionWrapperInternal secondaryDataKey={prNode} />
}

function LazyCommitChecksUpdateSubscriptionWrapper({
  id,
  secondaryQueryRef,
}: {
  id: string
  secondaryQueryRef?: PreloadedQuery<IssueRowSecondaryQuery> | null
}) {
  if (!secondaryQueryRef) return null

  return (
    <Suspense fallback={null}>
      <LazyCommitChecksUpdateSubscriptionWrapperFetched id={id} secondaryQueryRef={secondaryQueryRef} />
    </Suspense>
  )
}

/**
 * This component allows us to conditionally subscribe to commit checks updates (hooks can't be called conditionally)
 */
export function CommitChecksUpdateSubscriptionWrapper({commitId}: {commitId: string}) {
  const commitChecksConfig = useMemo(
    () => ({
      subscription: graphql`
        subscription PullRequestRowCommitChecksSubscription($id: ID!) {
          commitChecksUpdated(id: $id) {
            id
            statusCheckRollup {
              state
              contexts {
                checkRunCount
                checkRunCountsByState {
                  count
                  state
                }
              }
            }
          }
        }
      `,
      variables: {id: commitId},
    }),
    [commitId],
  )

  useSubscription<PullRequestRowCommitChecksSubscription>(commitChecksConfig)
  return <></>
}

type Props = {
  pullRequestKey: PullRequestRow_pullRequest$key
  metadataRef?: PreloadedQuery<IssueRowSecondaryQuery> | null
  /**
   * Href getter for the metadata badge links
   * @param type - metadata type
   * @param name - name of the metadata
   * @returns URL to the metadata
   */
  getMetadataHref: (type: keyof typeof QUERY_FIELDS, metadataName: string) => string
  onSelect?: (selected: boolean) => void
  onSelectRow: (payload?: {[key: string]: unknown} | AnalyticsEvent | undefined) => void
  isActive: boolean
  isSelected?: boolean
  reactionEmojiToDisplay?: {reaction: string; reactionEmoji: string}
  sortingItemSelected: string
  additionalAnalyticsContext?: Record<string, string>
  includeGitDataFromMainQuery?: boolean
  onNavigate: (to: To) => void
}

const PullRequestRowInternal = forwardRef(
  (
    {
      pullRequestKey,
      metadataRef,
      reactionEmojiToDisplay,
      getMetadataHref,
      onSelect,
      onSelectRow,
      isActive,
      isSelected,
      sortingItemSelected,
      // eslint-disable-next-line @eslint-react/no-unstable-default-props
      additionalAnalyticsContext = {},
      onNavigate,
      ...props
    }: Props,
    ref: ForwardedRef<HTMLAnchorElement>,
  ): ReactElement => {
    const {use_pull_request_subscriptions_enabled, pull_request_single_subscription} = useFeatureFlags()

    const pullRequest = useFragment(
      graphql`
        fragment PullRequestRow_pullRequest on PullRequest
        @argumentDefinitions(
          labelPageSize: {type: "Int!"}
          includeGitData: {type: "Boolean!", defaultValue: true}
          includeMilestone: {type: "Boolean!", defaultValue: true}
        ) {
          __typename
          id
          repository {
            name
            owner {
              login
            }
          }
          ...PullRequestItem
            @arguments(
              labelPageSize: $labelPageSize
              includeGitData: $includeGitData
              includeMilestone: $includeMilestone
            )

          headCommit @include(if: $includeGitData) {
            commit {
              id
            }
          }
          number
        }
      `,
      pullRequestKey,
    )

    if (use_pull_request_subscriptions_enabled && isLoggedIn()) {
      if (pull_request_single_subscription) {
        // Feature flagging for deploy safety only
        // eslint-disable-next-line react-compiler/react-compiler
        // eslint-disable-next-line react-hooks/rules-of-hooks
        usePullRequestRowSubscription(pullRequest.id)
      } else {
        // Feature flagging for deploy safety only
        // eslint-disable-next-line react-compiler/react-compiler
        // eslint-disable-next-line react-hooks/rules-of-hooks
        usePullRequestSubscriptions(pullRequest.id)
      }
    }
    const pullRequestOwner = pullRequest.repository.owner.login

    const url = pullRequestUrl({owner: pullRequestOwner, repo: pullRequest.repository.name, number: pullRequest.number})

    const navigateToPR = useCallback(() => {
      onNavigate(url)
    }, [url, onNavigate])

    const handleNavigation = useCallback(
      (event: React.MouseEvent | React.KeyboardEvent) => {
        onSelectRow({
          type: pullRequest.__typename,
          ...additionalAnalyticsContext,
        })

        event.stopPropagation()
        event.preventDefault()

        navigateToPR()
      },
      [additionalAnalyticsContext, onSelectRow, pullRequest.__typename, navigateToPR],
    )

    const toggleSelection = useCallback(() => {
      onSelect?.(!isSelected)
    }, [onSelect, isSelected])

    const includeStausCheckSubscription = use_pull_request_subscriptions_enabled && !pull_request_single_subscription

    return (
      <>
        {includeStausCheckSubscription &&
          (pullRequest.headCommit ? (
            <CommitChecksUpdateSubscriptionWrapper commitId={pullRequest.headCommit.commit.id} />
          ) : (
            <LazyCommitChecksUpdateSubscriptionWrapper id={pullRequest.id} secondaryQueryRef={metadataRef} />
          ))}
        <ScopedCommands
          commands={{
            'list-view-items-issues-prs:open-focused-item': navigateToPR,
            'list-view-items-issues-prs:toggle-focused-item-selection': toggleSelection,
          }}
          className={styles.row}
        >
          <PullRequestItem
            itemKey={pullRequest as PullRequestItem$key}
            metadataRef={metadataRef}
            isActive={isActive}
            isSelected={isSelected}
            reactionEmojiToDisplay={reactionEmojiToDisplay}
            showCommentCount
            showRepository
            showAssignees
            showLeadingRightSideContent={false}
            sortingItemSelected={sortingItemSelected}
            getMetadataHref={getMetadataHref}
            onSelect={onSelect}
            onClick={handleNavigation}
            href={url}
            ref={ref}
            {...props}
          />
        </ScopedCommands>
      </>
    )
  },
)

PullRequestRowInternal.displayName = 'PullRequestRow'

export const PullRequestRow = React.memo(PullRequestRowInternal)

try{ LazyCommitChecksUpdateSubscriptionWrapperInternal.displayName ||= 'LazyCommitChecksUpdateSubscriptionWrapperInternal' } catch {}
try{ LazyCommitChecksUpdateSubscriptionWrapperFetched.displayName ||= 'LazyCommitChecksUpdateSubscriptionWrapperFetched' } catch {}
try{ LazyCommitChecksUpdateSubscriptionWrapper.displayName ||= 'LazyCommitChecksUpdateSubscriptionWrapper' } catch {}
try{ CommitChecksUpdateSubscriptionWrapper.displayName ||= 'CommitChecksUpdateSubscriptionWrapper' } catch {}
try{ PullRequestRow.displayName ||= 'PullRequestRow' } catch {}